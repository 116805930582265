<template>
  <div class="bigBox">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/shopping' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>填写订单信息</el-breadcrumb-item>
        <el-breadcrumb-item>订单提交</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="contain flex jc-center ai-center">
        <div class="box">
          <img src="../img/success.png" />
          <h1 class="my-20">订单提交成功</h1>
          <span>请等待商家审核，审核通过后进行支付</span>
        </div>
      </div>
      <div class="btn flex jc-center">
        <el-button type="primary">
          <router-link to="shopping">进入首页</router-link></el-button
        >
        <el-button plain @click="toOrder">查看订单</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toOrder() {
      this.$router.push({
        path: "/mydiagnosisOrder",
      });
    },
  },
};
</script>

<style  lang='scss' scoped>
.bigBox {
  width: 1440px;
  margin: 0 auto;
  .breadcrumb {
    letter-spacing: 1px;
    height: 40px;
    color: #8c8c8c;
    background-color: #efeff4;
    padding: 0px 15px;
    .el-breadcrumb {
      display: flex;
      align-items: center;
      height: 40px;
    }
  }
  .content {
    border-left: 1px solid #d1d1d6;
    border-right: 1px solid #d1d1d6;
    border-bottom: 1px solid #d1d1d6;
    // box-shadow: 0px 0px 10px 0px rgba(0border: 1px solid #D1D1D6;, 0, 0, 0.2);
    .contain {
      width: 100%;
      height: 500px;
      .box {
        text-align: center;
        img {
          width: 90px;
          height: 90px;
        }
      }
    }
    .btn {
      height: 200px;
    }
  }
}
::v-deep .el-button {
  width: 176px;
  height: 40px;
}
</style>